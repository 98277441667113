import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { coursesApi } from "../../app/services/courses";

export const savedCoursesAdapter = createEntityAdapter({
  selectId: (course) => course.id,
});

const initialState = {
  savedCourses: savedCoursesAdapter.getInitialState(),
  hiddenTerms: [],
};

const savedCoursesSlice = createSlice({
  name: "savedCourses",
  initialState,
  reducers: {
    addSavedCourse: (state, action) => {
      savedCoursesAdapter.upsertOne(state.savedCourses, action);
    },
    removeSavedCourse: (state, action) => {
      savedCoursesAdapter.removeOne(state.savedCourses, action);
    },
    removeAllSavedCourses: (state, action) => {
      savedCoursesAdapter.removeAll(state.savedCourses);
    },
    updateSavedCourse: (state, action) => {
      savedCoursesAdapter.updateOne(state.savedCourses, action);
    },
    upsertSavedCourse: (state, action) => {
      savedCoursesAdapter.upsertOne(state.savedCourses, action);
    },
    setHiddenTerms: (state, action) => {
      state.hiddenTerms = action.payload;
    },
  },
});

export const selectHiddenTerms = (state) => state.savedCourses.hiddenTerms;

export const {
  selectAll: selectAllSavedCourses,
  selectIds: selectAllSavedCourseIds,
  selectById: selectSavedCourseById,
} = savedCoursesAdapter.getSelectors(
  (state) => state.savedCourses.savedCourses
);

export const {
  addSavedCourse,
  removeSavedCourse,
  removeAllSavedCourses,
  updateSavedCourse,
  upsertSavedCourse,
  setHiddenTerms,
} = savedCoursesSlice.actions;

export const addSavedCourseWithSearch =
  (course, selectedTerms = [], selectedInstructorIds = []) =>
  (dispatch) => {
    dispatch(
      addSavedCourse({
        ...course,
        selectedTerms,
        selectedInstructorIds,
      })
    );
    if (!course.classes) {
      const result = dispatch(
        coursesApi.endpoints.getCourse.initiate(course.id)
      );

      result
        .then(({ data }) => {
          dispatch(upsertSavedCourse(data));
        })
        .finally(() => {
          result.unsubscribe();
        });
    }
  };

export default savedCoursesSlice.reducer;
