import {
  addSavedCourse,
  removeSavedCourse,
} from "../features/saved_courses/savedCoursesSlice";

export const trackingMiddleware = (store) => (next) => (action) => {
  if (addSavedCourse.match(action)) {
    window.gtag("event", "add_course");
  } else if (removeSavedCourse.match(action)) {
    window.gtag("event", "remove_course");
  }
  return next(action);
};
