import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const toastAdapter = createEntityAdapter({
  selectId: (toast) => toast.id,
});

const toastSlice = createSlice({
  name: "toasts",
  initialState: toastAdapter.getInitialState(),
  reducers: {
    addOneToast: (state, action) =>
      toastAdapter.addOne(state, { id: uuidv4(), ...action.payload }),
    removeOneToast: toastAdapter.removeOne,
  },
});

export const { addOneToast, removeOneToast } = toastSlice.actions;

export const { selectAll: selectAllToasts } = toastAdapter.getSelectors(
  (state) => state.toasts
);

export default toastSlice.reducer;
