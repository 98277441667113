import { Toast as BSToast } from "bootstrap";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeOneToast, selectAllToasts } from "./toastSlice";

const Toast = ({ toast }) => {
  const dispatch = useDispatch();
  const toastRef = useRef();

  useEffect(() => {
    const toastInstance = BSToast.getOrCreateInstance(toastRef.current);
    toastInstance.show();
    toastRef.current.addEventListener("hidden.bs.toast", function () {
      dispatch(removeOneToast(toast.id));
    });

    return () => {
      toastInstance.dispose();
    };
  }, [dispatch, toast]);

  return (
    <div
      className={classNames("toast align-items-center", {
        "text-white bg-success border-0": toast.success,
        "text-white bg-danger border-0": toast.error,
      })}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      ref={toastRef}
    >
      <div className="d-flex">
        <div className="toast-body">{toast.message}</div>
        <button
          type="button"
          className={classNames("btn-close me-2 m-auto", {
            "btn-close-white": toast.success || toast.error,
          })}
          data-bs-dismiss="toast"
          aria-label="Close"
        />
      </div>
    </div>
  );
};

export const Toasts = () => {
  const toasts = useSelector(selectAllToasts);

  return (
    <div
      className="toast-container position-fixed bottom-0 end-0 p-3"
      style={{ zIndex: 11 }}
    >
      {_.map(toasts, (toast) => (
        <Toast toast={toast} key={toast.id} />
      ))}
    </div>
  );
};
