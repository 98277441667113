import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const selectedCoordsAdapter = createEntityAdapter({
  selectId: (course) => course.id,
});

const initialState = {
  limit: 20,
  selectedCoords: selectedCoordsAdapter.getInitialState(),
  searchTerm: "",
  selectedInstructors: [],
  selectedTerms: [],
  selectedAcademicGroups: [],
  orderBy: "id",
  order: "asc",
};

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setInstructors: (state, action) => {
      state.selectedInstructors = action.payload;
    },
    setSelectedTerms: (state, action) => {
      state.selectedTerms = action.payload;
    },
    setSelectedAcademicGroups: (state, action) => {
      state.selectedAcademicGroups = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setSelectedCourses: (state, action) => {
      selectedCoordsAdapter.setMany(state.selectedCoords, action.payload);
    },
    clearSelectedCourses: (state, action) => {
      selectedCoordsAdapter.removeAll(state.selectedCoords);
    },
  },
});

export const selectCount = (state) => state.courses.total;

export const selectCourses = (state) => state.courses.courses;

export const selectLimit = (state) => state.courses.limit;

export const selectSearchTerm = (state) => state.courses.searchTerm;

export const selectSelectedInstructors = (state) =>
  state.courses.selectedInstructors;

export const selectSelectedTerms = (state) => state.courses.selectedTerms;

export const selectSelectedAcademicGroups = (state) =>
  state.courses.selectedAcademicGroups;

export const selectOrderBy = (state) => state.courses.orderBy;

export const selectOrder = (state) => state.courses.order;

export const {
  setLimit,
  setSearchTerm,
  setInstructors,
  setSelectedTerms,
  setSelectedAcademicGroups,
  setOrderBy,
  setOrder,
  setSelectedCourses,
  clearSelectedCourses,
} = coursesSlice.actions;

export const {
  selectAll: selectSelectedCoords,
  selectById: selectSelectedCoordsById,
} = selectedCoordsAdapter.getSelectors((state) => state.courses.selectedCoords);

export default coursesSlice.reducer;
