import "@fortawesome/fontawesome-svg-core/styles.css";
import "bootstrap";
import i18n from "i18next";
import { Settings } from "luxon";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./app/store";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import translation from "./translation.en.json";

Settings.defaultLocale = "en";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
