import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import coursesReducer from "../features/courses/coursesSlice";
import savedCoursesReducer from "../features/saved_courses/savedCoursesSlice";
import toastReducer from "../features/toasts/toastSlice";
import { coursesApi } from "./services/courses";
import { saveStateEnhancer } from "./enhancers";
import { trackingMiddleware } from "./middlewares";

const loadState = () => {
  try {
    return JSON.parse(localStorage.getItem("state")) || undefined;
  } catch (e) {
    return undefined;
  }
};

export const store = configureStore({
  preloadedState: loadState(),
  reducer: {
    courses: coursesReducer,
    savedCourses: savedCoursesReducer,
    toasts: toastReducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
  },
  enhancers: [saveStateEnhancer(["savedCourses"])],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([trackingMiddleware, coursesApi.middleware]),
});

setupListeners(store.dispatch);
