import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import { NavBar } from "./features/navbar/NavBar";
import { Toasts } from "./features/toasts/Toasts";

const About = lazy(() => import("./features/about/About"));
const Courses = lazy(() => import("./features/courses/Courses"));
const SavedCourses = lazy(() =>
  import("./features/saved_courses/SavedCourses")
);

const Spinner = () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

function App() {
  return (
    <div>
      <NavBar />
      <Switch>
        <Suspense fallback={<Spinner />}>
          <Route exact path="/">
            <About />
          </Route>
          <Route path="/courses">
            <Courses />
          </Route>
          <Route path="/savedCourses">
            <SavedCourses />
          </Route>
        </Suspense>
      </Switch>
      <Toasts />
    </div>
  );
}

export default App;
