import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const prepareParams = ({
  q = "",
  selectedInstructorIds = [],
  selectedTerms = [],
  selectedAcademicGroupIds = [],
} = {}) => {
  const params = new URLSearchParams();

  if (q) {
    params.append("q", q);
  }

  for (let selectedInstructorId of selectedInstructorIds) {
    params.append("instructor_ids", selectedInstructorId);
  }

  for (let selectedTerm of selectedTerms) {
    params.append("terms", selectedTerm);
  }

  for (let selectedAcademicGroupId of selectedAcademicGroupIds) {
    params.append("academic_group_ids", selectedAcademicGroupId);
  }

  return params;
};

export const coursesApi = createApi({
  reducerPath: "coursesApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/" }),
  endpoints: (build) => ({
    getCourse: build.query({
      query: (courseId) => `courses/${courseId}`,
    }),
    getCourses: build.query({
      query: ({
        offset = 0,
        limit = 20,
        searchTerm = "",
        selectedInstructorIds = [],
        selectedTerms = [],
        selectedAcademicGroupIds = [],
        orderBy = "id",
        order = "asc",
      }) => {
        const params = prepareParams({
          q: searchTerm,
          selectedInstructorIds,
          selectedTerms,
          selectedAcademicGroupIds,
        });
        params.append("limit", limit.toString());
        params.append("offset", offset.toString());
        params.append("order_by", orderBy);
        params.append("order", order);

        return `courses?${params}`;
      },
    }),
    getCoursesCount: build.query({
      query: ({
        searchTerm = "",
        selectedInstructorIds = [],
        selectedTerms = [],
        selectedAcademicGroupIds = [],
      }) => {
        const params = prepareParams({
          q: searchTerm,
          selectedInstructorIds,
          selectedTerms,
          selectedAcademicGroupIds,
        });

        return `courses/count?${params}`;
      },
    }),
    getCoords: build.query({
      query: ({
        searchTerm = "",
        selectedInstructorIds = [],
        selectedTerms = [],
        selectedAcademicGroupIds = [],
      }) => {
        const params = prepareParams({
          q: searchTerm,
          selectedInstructorIds,
          selectedTerms,
          selectedAcademicGroupIds,
        });

        return `courses/coordinates?${params}`;
      },
    }),
    getTerms: build.query({
      query: () => "courses/terms",
    }),
    getAcademicGroups: build.query({
      query: () => "courses/academic_groups",
    }),
    getInstructors: build.query({
      query: () => "courses/instructors",
    }),
  }),
});

export const {
  useGetCourseQuery,
  useGetCoursesQuery,
  useGetCoursesCountQuery,
  useGetCoordsQuery,
  useGetTermsQuery,
  useGetAcademicGroupsQuery,
  useGetInstructorsQuery,
} = coursesApi;
