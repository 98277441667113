import { Link, NavLink, useLocation, useRouteMatch } from "react-router-dom";

const NavItem = (props) => (
  <li className="nav-item">
    <NavLink className="nav-link" activeClassName="active" {...props} />
  </li>
);

export const NavBar = () => {
  const { search } = useLocation();
  const matchCourses = useRouteMatch("/courses/(\\d+)");

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary mb-lg-4 mb-1">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          Course Viewer
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <NavItem exact to="/">
              About
            </NavItem>
            <li className="nav-item dropdown">
              <button
                className="btn btn-link nav-link dropdown-toggle"
                type="button"
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Courses
              </button>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <NavLink
                    className="dropdown-item"
                    activeClassName="active"
                    to={{ pathname: "/courses", search }}
                    isActive={() => matchCourses}
                  >
                    Course List
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    activeClassName="active"
                    to={{ pathname: "/courses/map", search }}
                  >
                    Course Map
                  </NavLink>
                </li>
              </ul>
            </li>
            <NavItem to="/savedCourses">Saved Courses</NavItem>
          </ul>
        </div>
      </div>
    </nav>
  );
};
