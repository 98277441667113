import _ from "lodash";

const saveState = _.debounce(
  (state) => localStorage.setItem("state", JSON.stringify(state)),
  200
);

export const saveStateEnhancer = (keys) => {
  const filterState = _.partial(_.pick, _, keys);

  const stateEqual = _.overArgs(_.isEqual, [filterState, filterState]);

  return (createStore) => (reducer, initialState, enhancer) => {
    const saveStateReducer = (state, action) => {
      const newState = reducer(state, action);

      if (!stateEqual(state, newState)) {
        try {
          saveState(filterState(newState));
        } catch (e) {}
      }

      return newState;
    };

    return createStore(saveStateReducer, initialState, enhancer);
  };
};
